//Used for storing user information that will be used throughout the app.

import { makeAutoObservable } from "mobx";
import { RootStore } from "./rootStore";

export class UserMetaStore {
  root: RootStore;
  groups: string[] = [];
  cognitoUID = "";
  email = "";
  firstName = "";
  lastName = "";

  constructor(root: RootStore) {
    this.root = root;
    this.groups = [""];
    makeAutoObservable(this);
  }
  get getUserGroups(): string[] {
    return this.groups;
  }
  setUserGroups(groupValue: string[]) {
    this.groups = groupValue;
  }
  get getCognitoUID(): string {
    return this.cognitoUID;
  }
  setCognitoUID(uid: string) {
    this.cognitoUID = uid;
  }
  get getUserEmail(): string {
    return this.email;
  }
  setUserEmail(email: string) {
    this.email = email;
  }
  get getUserFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
  setUserFullName(first: string, last: string) {
    this.firstName = first;
    this.lastName = last;
  }
}
