import { Auth } from "aws-amplify";
import { AuthPiece, SignUp } from "aws-amplify-react";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";
import { IAuthPieceState } from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import * as React from "react";

import {
  Grid,
  Header,
  Form,
  Segment,
  Button,
  Message,
} from "semantic-ui-react";

export interface IGatekeeperSignUpProps extends IAuthenticatorProps {
  setUsername: (value: string) => void;
}
interface IGatekeeperSignUpStateProps extends IAuthPieceState {
  missingEmailError: boolean;
  invalidEmailError: boolean;
  mismatchEmailError: boolean;
  missingNameFieldsError: boolean;
  invalidPasswordError: boolean;
  mismatchPasswordError: boolean;
  awsError: boolean;
  awsErrorMessage: string;
}

export default class GateKeeperSignUp extends AuthPiece<
  IGatekeeperSignUpProps,
  IGatekeeperSignUpStateProps
> {
  constructor(props: any) {
    super(props);
    this._validAuthStates = ["signUp"];
    this.state = {
      missingEmailError: false,
      invalidEmailError: false,
      mismatchEmailError: false,
      missingNameFieldsError: false,
      invalidPasswordError: false,
      mismatchPasswordError: false,
      awsError: false,
      awsErrorMessage: "",
    };
  }
  signUp = async (evt: any) => {
    if (evt) {
      evt.preventDefault();
    }
    //reset generic aws message state...
    this.setState({ awsErrorMessage: "", awsError: false });

    try {
      // Does a check to make sure at minimum all the information meets our userpool requirements for signup
      let isValidInfo = this.verifyRequired(
        this.inputs.username,
        this.inputs.usernameVerify,
        this.inputs.first,
        this.inputs.last,
        this.inputs.password,
        this.inputs.passwordVerify
      );
      if (isValidInfo) {
        let signupResponse = await Auth.signUp({
          username: this.inputs.username,
          password: this.inputs.password,
          attributes: {
            name: this.inputs.first,
            family_name: this.inputs.last,
            email: this.inputs.username,
          },
        });
        //change state to confirmSignup, and pass along the username from signup success
        this.props.setUsername(this.inputs.username);
        super.changeState("confirmSignUp", {
          username: signupResponse.user.getUsername(),
        });
      }
    } catch (err) {
      switch (err.code) {
        case "UsernameExistsException":
          this.setState({ awsError: true, awsErrorMessage: err.message });
          break;
      }
    }
  };

  //regex for checking if an email is at least a valid format.
  verifyValidEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  //regex for checking the password agains the userpool password rules.
  verifyPassword = (password: string): boolean => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(String(password));
  };

  // Verify all required fields meet minimum requirements for signup
  verifyRequired = (
    email: string,
    emailMatch: string,
    first: string,
    last: string,
    password: string,
    passwordVerify: string
  ): boolean => {
    let isEmailMatching = false;
    //first check if the email was valid..
    let isValidEmail = this.verifyValidEmail(email);
    if (isValidEmail) {
      //reset state for valid email
      this.setState({ invalidEmailError: false });
    } else {
      //Email was not valid
      this.setState({ invalidEmailError: true });
      return false;
    }
    //check to see if it matches with verification email
    isEmailMatching = email == emailMatch;
    if (isEmailMatching) {
      //reset state for matching emails..
      this.setState({ mismatchEmailError: false });
      //other checks
    } else {
      //verification email did not match original.
      this.setState({ mismatchEmailError: true });
      return false;
    }
    //Check that full name has been provided
    //we check for both undefined and empty string values
    if (!first || first === "" || !last || last === "") {
      this.setState({ missingNameFieldsError: true });
      return false;
    } else {
      console.log(first, last);
      this.setState({ missingNameFieldsError: false });
    }
    //check user password against the rules of the userpool
    let isPasswordValid = this.verifyPassword(password);

    if (isPasswordValid) {
      this.setState({ invalidPasswordError: false });
    } else {
      this.setState({ invalidPasswordError: true });
      return false;
    }
    //make sure the passwords match
    if (password !== passwordVerify) {
      this.setState({ mismatchPasswordError: true });
      return false;
    } else {
      this.setState({ mismatchPasswordError: false });
    }

    return true;
  };

  public render() {
    if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState)
    ) {
      return (
        <Grid
          style={{ height: "100vh" }}
          verticalAlign="middle"
          textAlign="center"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal">
              Create new account
            </Header>
            <Form size="large" error>
              <Segment textAlign="left" stacked>
                <Form.Input
                  id="username"
                  key="username"
                  name="username"
                  labelPosition="left"
                  label="E-mail Address"
                  onChange={this.handleInputChange}
                />

                <Form.Input
                  id="usernameVerify"
                  key="usernameVerify"
                  name="usernameVerify"
                  labelPosition="left"
                  label="Verify E-mail Address"
                  onChange={this.handleInputChange}
                />
                <Form.Group>
                  <Form.Input
                    id="first"
                    key="first"
                    name="first"
                    label="First Name"
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    id="last"
                    key="last"
                    name="last"
                    label="Last Name"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Input
                  id="password"
                  key="password"
                  name="password"
                  labelPosition="left"
                  label="Password"
                  type="password"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  id="passwordVerify"
                  key="passwordVerify"
                  name="passwordVerify"
                  labelPosition="left"
                  label="Verify Password"
                  type="password"
                  onChange={this.handleInputChange}
                />
                <SignUpErrorMessageDisplay
                  invalidEmailError={this.state.invalidEmailError}
                  missingEmailError={this.state.missingEmailError}
                  mismatchEmailError={this.state.mismatchEmailError}
                  missingNameFieldsError={this.state.missingNameFieldsError}
                  invalidPasswordError={this.state.invalidPasswordError}
                  mismatchPasswordError={this.state.mismatchPasswordError}
                  awsError={this.state.awsError}
                  awsErrorMessage={this.state.awsErrorMessage}
                />
                <Button fluid color="teal" onClick={(e: any) => this.signUp(e)}>
                  Sign up
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
}

const SignUpErrorMessageDisplay: React.FunctionComponent<IGatekeeperSignUpStateProps> = (
  props
) => {
  return (
    <React.Fragment>
      <Message
        size="mini"
        error
        hidden={!props.invalidEmailError}
        header="Email Error:"
        content="Not a valid E-mail address"
      />
      <Message
        size="mini"
        error
        hidden={!props.mismatchEmailError}
        header="Email Mismatch:"
        content="Verification Email does not match original"
      />
      <Message
        size="mini"
        error
        hidden={!props.missingNameFieldsError}
        header="Name Field Error:"
        content="Please provide both a first and last name."
      />
      <Message size="mini" error hidden={!props.invalidPasswordError}>
        <p>Your password does not meet the following minimun requirements:</p>
        <Message.List>
          <Message.Item>8 characters long</Message.Item>
          <Message.Item>1 symbol</Message.Item>
          <Message.Item>1 capital letter</Message.Item>
          <Message.Item>1 lowercase letter</Message.Item>
          <Message.Item>1 number</Message.Item>
        </Message.List>
      </Message>
      <Message
        size="mini"
        error
        hidden={!props.mismatchPasswordError}
        header="Password Mismatch Error:"
        content="Passwords do not match."
      />
      <Message
        size="mini"
        error
        hidden={!props.awsError}
        content={props.awsErrorMessage}
      />
    </React.Fragment>
  );
};
