import { Auth } from "aws-amplify";
import AuthPiece, {
  IAuthPieceProps,
  IAuthPieceState,
} from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import * as React from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import logo from "../../assets/images/logo.svg";

export interface IGatekeeperSignInProps extends IAuthPieceProps {
  setUsername: (value: string) => void;
}

export default class GatekeeperSignIn extends AuthPiece<
  IGatekeeperSignInProps,
  IAuthPieceState
> {
  constructor(props: IGatekeeperSignInProps) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {};
  }

  signIn = async (evt: any) => {
    if (evt) {
      evt.preventDefault();
    }
    const username = this.inputs.username;
    //we are storing off the username here so that we can grab it later
    // seems the auth mehtods are busted when overriding the components.
    this.props.setUsername(username);
    const password = this.inputs.password;
    try {
      await Auth.signIn(username, password);
      //TODO: need to implement the state change to SignedIn here...
    } catch (err) {
      switch (err.code) {
        case "UserNotConfirmedException":
          console.log(err.code);
          super.changeState("confirmSignUp", this.inputs.username);
          break;
        case "NotAuthorizedException":
          console.log(err.code);
          break;
        case "UserNotFoundException":
          console.log(err.code);
          break;
        default:
          console.log(err);
      }
    }
  };

  handleInputChange = (evt: any) => {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.inputs[name] = check_type ? checked : value;
    this.inputs["checkedValue"] = check_type ? value : null;
  };

  public render() {
    if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState)
    ) {
      return (
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image src={logo} centered size="tiny" />
            <Header
              as="h1"
              color="blue"
              textAlign="center"
              style={{ fontFamily: "Noto Serif", marginTop: "-6px" }}
            >
              Gatekeeper
            </Header>
            <Form size="large">
              <Segment color="blue" stacked>
                <Form.Input
                  fluid
                  id="username"
                  key="username"
                  name="username"
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  fluid
                  id="password"
                  key="password"
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={this.handleInputChange}
                />
                <Button
                  color="blue"
                  fluid
                  size="large"
                  onClick={(e) => this.signIn(e)}
                  style={{ marginBottom: "8px" }}
                >
                  Login
                </Button>
                <p>
                  Forgot your password?{" "}
                  <a
                    onClick={() => {
                      super.changeState("forgotPassword");
                    }}
                  >
                    Reset Password.
                  </a>
                </p>
              </Segment>
            </Form>
            <Message>
              Need an account?{" "}
              <a
                onClick={() => {
                  super.changeState("signUp");
                }}
              >
                Sign Up
              </a>
            </Message>
          </Grid.Column>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
}
