import React, { useContext } from "react";
import { RootStore } from "./stores/rootStore";
let store: RootStore;
const StoreContext = React.createContext<RootStore | undefined>(undefined);

const StoreProvider: React.FC = ({ children }) => {
  //singleton rootstore instance
  const root = new RootStore();
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};

const useRootStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error("useRootStore must be used within RootStoreProvider");
  }
  return context;
};

export { StoreProvider, useRootStore };
