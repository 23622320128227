import { TRoute } from ".";
import CognitoAssignGroupContainer from "../containers/cognitoContainers/cognitoAssignGroupContainer";
import CognitoRemoveUserFromGroupContainer from "../containers/cognitoContainers/cognitoRemoveUserFromGroupContainer";
import CognitoMainView from "../views/cognito/mainView";
import NoGroupsView from "../views/error/noGroupsView";

const defaultRoutes: TRoute[] = [
  {
    path: "/noGroups",
    component: NoGroupsView,
    exact: true,
  },
];

export default defaultRoutes;
