import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useContext, useEffect } from "react";
import * as _ from "lodash";
import { Link, BrowserRouter as Router, useHistory } from "react-router-dom";
import { Container, Menu, Image, Visibility } from "semantic-ui-react";
import logo from "../../assets/images/logo.svg";
import { useRootStore } from "../../mobx/context";
import { checkUserHasGroup } from "../../utils/cognito/groupUtils";
const AppBar: FunctionComponent = observer(() => {
  const store = useRootStore();
  const history = useHistory();
  let visibleMenuItems: any = [];
  const logout = () => {
    Auth.signOut();
    history.replace("/");
  };
  const cognitoMenuItemGroups = ["Gods"];
  const EntitlementMenuItemGroups = ["Gods", "Devs"];
  return (
    <div>
      <Menu stackable inverted>
        <Container>
          <Menu.Item color="blue">
            <Image src={logo} size="mini" />
            <Menu.Header
              style={{
                marginLeft: "8px",
                textTransform: "capitalize",
                fontFamily: "Noto Serif",
              }}
            >
              Gatekeeper
            </Menu.Header>
          </Menu.Item>
          <Menu.Item as="a">Dashboard</Menu.Item>

          {checkUserHasGroup(
            EntitlementMenuItemGroups,
            store.UserMetaStore.getUserGroups
          ) ? (
            <Menu.Item as="a">Entitlements</Menu.Item>
          ) : (
            <div />
          )}
          {checkUserHasGroup(
            cognitoMenuItemGroups,
            store.UserMetaStore.getUserGroups
          ) ? (
            <Menu.Item>
              <Link to="/cognito">Cognito</Link>
            </Menu.Item>
          ) : (
            <div />
          )}
          <Menu.Menu position="right">
            <Menu.Item as="a" style={{ textTransform: "capitalize" }}>
              {store.UserMetaStore.getUserFullName}
            </Menu.Item>
            <Menu.Item as="a" onClick={logout}>
              Logout
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    </div>
  );
});

export default AppBar;
