import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify, { Auth } from "aws-amplify";
import "semantic-ui-css/semantic.min.css";
import { Authenticator } from "aws-amplify-react";
import AuthWrapper from "./components/amplify-auth/authWrapper";
import { ToastProvider } from "react-toast-notifications";
import { StoreProvider } from "./mobx/context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";

//setup the apollo gql client
const client = new ApolloClient({
  uri: process.env.REACT_APP_GQL_CONNECTION,
  cache: new InMemoryCache(),
});
//Provide userpool id, and appclient id
const awsConfig = {
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEBCLIENT_ID,
    mandatorySignIn: true,
  },
};
//Configure userpool, and appclient to work with amplify
Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <ToastProvider placement="bottom-center">
        <ApolloProvider client={client}>
          <Router>
            <Authenticator hideDefault={true} amplifyConfig={awsConfig}>
              <AuthWrapper />
            </Authenticator>
          </Router>
        </ApolloProvider>
      </ToastProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
