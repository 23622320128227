import { Auth } from "aws-amplify";
import AuthPiece, {
  IAuthPieceProps,
  IAuthPieceState,
} from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import { IGatekeeperSignUpProps } from "./gatekeeperSignUp";

export interface IGatekeeperConfirmSignUpProps extends IAuthPieceProps {
  getUsername: () => string;
}
export interface IGatekeeperConfirmSignupState extends IAuthPieceState {
  verificationSuccessful: boolean;
}
export default class GatekeeperConfirmSignUp extends AuthPiece<
  IGatekeeperConfirmSignUpProps,
  IGatekeeperConfirmSignupState
> {
  constructor(props: IGatekeeperSignUpProps) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
    this.state = {
      verificationSuccessful: false,
    };
  }

  confirm = async () => {
    try {
      //usernameFromAuthData is busted, fallback to our workaround and fetch username from AuthWrapper State
      const username = this.usernameFromAuthData() || this.props.getUsername();
      const { code } = this.inputs;
      if (!Auth || typeof Auth.confirmSignUp !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }

      let response = await Auth.confirmSignUp(username, code);

      if (response === "SUCCESS") {
        this.setState({ verificationSuccessful: true });
        setTimeout(() => {
          super.changeState("signedUp");
          //reset the verification check
          this.setState({ verificationSuccessful: false });
        }, 2000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  resend = async () => {
    try {
      //see similiar line in confirm function...
      const username = this.usernameFromAuthData() || this.props.getUsername();
      console.log(this.inputs);
      if (!Auth || typeof Auth.resendSignUp !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }
      let response = await Auth.resendSignUp(username);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  public render() {
    if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState) &&
      !this.state.verificationSuccessful
    ) {
      return (
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              Verify Account
            </Header>
            <Form size="large">
              <Segment stacked>
                <Message
                  info
                  header="Enter Verification Code:"
                  content="We've sent you a verification code to the email used in the sign up process"
                />
                <Form.Input
                  fluid
                  id="code"
                  key="code"
                  name="code"
                  placeholder="Verification Code"
                  onChange={this.handleInputChange}
                />
                <Button color="teal" fluid size="large" onClick={this.confirm}>
                  Verify
                </Button>
              </Segment>
            </Form>
            <Message>
              No Code? <a onClick={this.resend}>Resend Code</a>
            </Message>
          </Grid.Column>
        </Grid>
      );
    } else if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState) &&
      this.state.verificationSuccessful
    ) {
      return (
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              Verification Successful!
            </Header>
            <Icon name="check circle outline" size="massive" color="green" />
            <Message info content="Redirecting you to login..." />
          </Grid.Column>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
}
