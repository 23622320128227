import { observer } from "mobx-react-lite";
import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";

const NoGroupsView = observer(() => {
  return (
    <Grid verticalAlign="middle" textAlign="center" style={{ height: "100vh" }}>
      <Grid.Column width="9">
        <Segment stacked>
          <Grid.Row style={{ marginBottom: "1em" }}>
            <Icon size="huge" color="orange" name="warning sign" />
          </Grid.Row>
          <Grid.Row>
            <Header size="large">Missing Group Access</Header>
          </Grid.Row>
          <Container>
            <Message warning style={{ marginTop: "1em" }}>
              You are missing the appropriate group assignment for this
              application. Please contact an administrator to resolve this
              issue.
            </Message>
          </Container>
          <Button
            color="blue"
            icon
            labelPosition="left"
            style={{ marginTop: "1em" }}
          >
            <Icon name="log out" />
            Logout
          </Button>
        </Segment>
      </Grid.Column>
    </Grid>
  );
});

export default NoGroupsView;
