import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { Switch, useHistory } from "react-router";
import { Container, Divider, Grid, Header, Menu } from "semantic-ui-react";
import { RouteWithSubRoutes, TRoute } from "../../routes";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import { useRootStore } from "../../mobx/context";
import { checkUserHasGroup } from "../../utils/cognito/groupUtils";
export interface ICognitoMainViewProps {
  routes: TRoute[];
}
const CognitoMainView: FunctionComponent<ICognitoMainViewProps> = observer(
  ({ routes }) => {
    const store = useRootStore();
    const history = useHistory();
    //if user does not have the right group redirect them.
    if (!checkUserHasGroup(["Gods"], store.UserMetaStore.getUserGroups)) {
      history.push("/");
    } else {
      history.push("/cognito/assignGroup");
    }
    return (
      <Container fluid>
        <Grid padded stackable>
          <Grid.Row>
            <Grid.Column width="2">
              <Header as="h1" color="grey" style={{ marginTop: "4px" }}>
                Cognito
              </Header>
            </Grid.Column>
            <Grid.Column width="12" verticalAlign="middle">
              <Menu>
                <Menu.Item>
                  <Link to="/cognito/assignGroup">Assign Group</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/cognito/unassignGroup">Unassign Group</Link>
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />

        <Switch>
          {_.map(routes, (route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
      </Container>
    );
  }
);

export default CognitoMainView;
