import * as React from "react";
import GatekeeperSignIn from "./gatekeeperSignIn";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";
import App from "../../App";
import GateKeeperSignUp from "./gatekeeperSignUp";
import GatekeeperConfirmSignUp from "./gatekeeperConfirmSignup";
import GatekeeperForgotPassword from "./gatekeeperForgotPassword";

interface IAuthWrapperState {
  username: string;
}

export default class AuthWrapper extends React.Component<
  IAuthenticatorProps,
  IAuthWrapperState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
    };
  }

  setUsername = (value: string) => {
    this.setState({ username: value });
  };

  getUsername = (): string => {
    return this.state.username;
  };

  public render() {
    return (
      <div>
        <GatekeeperSignIn
          authState={this.props.authState}
          setUsername={this.setUsername}
          onStateChange={this.props.onStateChange}
        />
        <GateKeeperSignUp
          authState={this.props.authState}
          onStateChange={this.props.onStateChange}
          setUsername={this.setUsername}
        />
        <GatekeeperConfirmSignUp
          authState={this.props.authState}
          onStateChange={this.props.onStateChange}
          getUsername={this.getUsername}
        />
        <GatekeeperForgotPassword
          authState={this.props.authState}
          onStateChange={this.props.onStateChange}
        />
        <App
          authState={this.props.authState}
          onStateChange={this.props.onStateChange}
        />
      </div>
    );
  }
}
