import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Dropdown } from "semantic-ui-react";
import { CognitoGroupAttributes } from "../../gqlSchemaTypes/types";

const GROUP_QUERY = gql`
  query getCognitoGroups {
    listCognitoGroups {
      GroupName
    }
  }
`;

export interface ICognitoGroupDropDownProps {
  setSelectedGroups: (groups: string) => void;
  getSelectedGroup: string;
}

const CognitoGroupDropDown: FunctionComponent<ICognitoGroupDropDownProps> = observer(
  ({ setSelectedGroups, getSelectedGroup }) => {
    const { loading, error, data } = useQuery(GROUP_QUERY);
    let formattedGroups;
    //format the data returned for the dropdown component
    if (!loading && data) {
      formattedGroups = _.map(
        data.listCognitoGroups,
        (item: CognitoGroupAttributes, index) => ({
          key: item.GroupName,
          text: item.GroupName,
          value: item.GroupName,
        })
      );
      console.log(formattedGroups);
    }
    return (
      <Dropdown
        placeholder="Groups"
        fluid
        search
        selection
        value={getSelectedGroup}
        onChange={(e, d) => {
          if (d.value) {
            setSelectedGroups(d.value as string);
          }
        }}
        loading={loading}
        options={formattedGroups}
      />
    );
  }
);

export default CognitoGroupDropDown;
