import { observer, useLocalObservable } from "mobx-react-lite";
import { FunctionComponent } from "react";
import * as _ from "lodash";
import { CognitoGroupDropDown } from "../../components/cognitoComponents/index";
import {
  Button,
  Container,
  Form,
  Message,
  Segment,
  Icon,
  Header,
} from "semantic-ui-react";
import CognitoUserDropDown from "../../components/cognitoComponents/cognitoUserDropDown";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { UserGroupResponse } from "../../gqlSchemaTypes/types";

const SAVE_GROUP_ASSIGNMENT = gql`
  mutation SaveGroupChanges($Username: String!, $Groupname: String!) {
    assignUserToGroup(Username: $Username, GroupName: $Groupname) {
      Username
      GroupName
    }
  }
`;

const CognitoAssignGroupContainer: FunctionComponent = observer(() => {
  //toast message hook
  const { addToast } = useToasts();
  //local store for managing the component's state only when rendered.
  const assignGroupLocalStore = useLocalObservable(() => ({
    selectedUserID: "",
    selectedGroupsForAssignment: "",
    selectUsersByID(ids: string) {
      this.selectedUserID = ids;
    },
    get getSelectedUserID() {
      return this.selectedUserID;
    },
    setTargetGroups(groups: string) {
      console.log(groups);
      this.selectedGroupsForAssignment = groups;
    },
    get getSelectedTargetGroups() {
      return this.selectedGroupsForAssignment;
    },
    get isSaveDisabled() {
      return (
        this.selectedUserID.length === 0 ||
        this.selectedGroupsForAssignment.length === 0
      );
    },
    resetState() {
      this.selectedUserID = "";
      this.selectedGroupsForAssignment = "";
    },
  }));

  const [saveGroup, { data, loading, error }] = useMutation(
    SAVE_GROUP_ASSIGNMENT
  );

  const onSaveClick = (e: any) => {
    e.preventDefault();
    saveGroup({
      variables: {
        Username: assignGroupLocalStore.selectedUserID,
        Groupname: assignGroupLocalStore.getSelectedTargetGroups,
      },
    })
      .then((d) => {
        console.log("successful");
        const { Username, GroupName } = d.data
          .assignUserToGroup as UserGroupResponse;
        addToast(`Successfully added ${Username} to ${GroupName}`, {
          appearance: "success",
          autoDismiss: true,
        });
        assignGroupLocalStore.resetState();
      })
      .catch((err: ApolloError) => {
        addToast(err.message, {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };
  return (
    <Container>
      <Header color="blue">
        <Icon name="group" />
        Assign Group
      </Header>
      <Form>
        <Segment>
          <Message info>
            <Icon name="info circle" />
            This will append the selected group to a user, it will not override
            existing group assignments.
          </Message>
          <Form.Field>
            <label>Select Users</label>
            <CognitoUserDropDown
              getSelectedUser={assignGroupLocalStore.getSelectedUserID}
              setSelectedUser={assignGroupLocalStore.selectUsersByID}
            />
          </Form.Field>
          <Form.Field>
            <label>Group Assignment</label>
            <CognitoGroupDropDown
              setSelectedGroups={assignGroupLocalStore.setTargetGroups}
              getSelectedGroup={assignGroupLocalStore.getSelectedTargetGroups}
            />
          </Form.Field>
        </Segment>
        <Button
          color="green"
          floated="right"
          disabled={assignGroupLocalStore.isSaveDisabled}
          onClick={onSaveClick}
          loading={loading}
        >
          Save
        </Button>
      </Form>
    </Container>
  );
});

export default CognitoAssignGroupContainer;
