import { Auth } from "aws-amplify";
import AuthPiece, {
  IAuthPieceProps,
  IAuthPieceState,
} from "aws-amplify-react/lib-esm/Auth/AuthPiece";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import { IGatekeeperSignUpProps } from "./gatekeeperSignUp";

export interface IGatekeeperForgotPasswordProps extends IAuthPieceProps {}
export interface IGatekeeperForgotPasswordState extends IAuthPieceState {
  username: string;
}
export default class GatekeeperForgotPassword extends AuthPiece<
  IGatekeeperForgotPasswordProps,
  IGatekeeperForgotPasswordState
> {
  constructor(props: IGatekeeperSignUpProps) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
    this.state = {
      username: "",
    };
  }

  //regex for checking if an email is at least a valid format.
  verifyValidEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // alerts cognito that we are trying to reset a password, this will email a reset code to the user
  requestCode = async () => {
    try {
      const { username } = this.inputs;
      if (this.verifyValidEmail(username)) {
        let forgotPasswordResponse = await Auth.forgotPassword(username);
        console.log(forgotPasswordResponse);
        //setting the username will trigger a render change on the component for password/code input.
        this.setState({ username });
      }
    } catch (err) {
      console.log(err);
    }
  };

  resetPassword = async () => {
    try {
      const { code, password } = this.inputs;
      //Send the request for password reset to cognito, using code, username, and new password.
      let passwordResponse = await Auth.forgotPasswordSubmit(
        this.state.username,
        code,
        password
      );
      this.setState({ username: "" });
      super.changeState("signIn");
    } catch (err) {
      console.log(err);
    }
  };

  resend = async () => {
    try {
      //see similiar line in confirm function...
      const username = this.usernameFromAuthData() || this.state.username;
      console.log(this.inputs);
      if (!Auth || typeof Auth.resendSignUp !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }
      let response = await Auth.resendSignUp(username);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  public render() {
    if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState) &&
      this.state.username === ""
    ) {
      return (
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              Password Reset
            </Header>
            <Form size="large">
              <Segment stacked>
                <Message
                  info
                  content="Please, enter the email associated with the account. We will send a reset code that is required in the next step to set a new password for your account."
                />
                <Form.Input
                  fluid
                  id="username"
                  key="username"
                  name="username"
                  placeholder="Email"
                  onChange={this.handleInputChange}
                />
                <Button
                  color="teal"
                  fluid
                  size="large"
                  onClick={this.requestCode}
                >
                  Submit
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      );
    } else if (
      this.props.authState &&
      this._validAuthStates.includes(this.props.authState) &&
      this.state.username !== ""
    ) {
      return (
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              Create New Password
            </Header>
            <Form size="large">
              <Segment stacked>
                <Message
                  info
                  content="Enter a new password for your account, along with the reset code that was emailed to you."
                />
                <Form.Input
                  fluid
                  id="code"
                  key="code"
                  name="code"
                  placeholder="Reset Code"
                  onChange={this.handleInputChange}
                />
                <Form.Input
                  fluid
                  id="password"
                  key="password"
                  name="password"
                  placeholder="New Password"
                  onChange={this.handleInputChange}
                />
                <Button
                  color="teal"
                  fluid
                  size="large"
                  onClick={this.resetPassword}
                >
                  Change Password
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
}
