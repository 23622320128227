import { TRoute } from ".";
import CognitoAssignGroupContainer from "../containers/cognitoContainers/cognitoAssignGroupContainer";
import CognitoRemoveUserFromGroupContainer from "../containers/cognitoContainers/cognitoRemoveUserFromGroupContainer";
import CognitoMainView from "../views/cognito/mainView";

const cognitoRoutes: TRoute[] = [
  {
    path: "/cognito",
    component: CognitoMainView,
    exact: false,
    routes: [
      { path: "/cognito/assignGroup", component: CognitoAssignGroupContainer },
      {
        path: "/cognito/unassignGroup",
        component: CognitoRemoveUserFromGroupContainer,
      },
    ],
  },
];

export default cognitoRoutes;
