import React, { FunctionComponent, useContext } from "react";
import "./App.css";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";
import Auth from "@aws-amplify/auth";
import AppBar from "./components/appBar/appBar";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useRootStore } from "./mobx/context";
import { observer } from "mobx-react-lite";
import { Container } from "semantic-ui-react";
import { routes, RouteWithSubRoutes } from "./routes";
import _ from "lodash";
export interface IAppProps extends IAuthenticatorProps {}
const App: FunctionComponent<IAppProps> = observer(({ authState }) => {
  const store = useRootStore();
  const history = useHistory();
  if (authState === "signedIn") {
    Auth.currentSession().then((c) => {
      let idToken = c.getIdToken().payload;
      console.log(idToken);
      store.UserMetaStore.setUserGroups(idToken["cognito:groups"]);
      store.UserMetaStore.setCognitoUID(idToken["cognito:username"]);
      store.UserMetaStore.setUserFullName(
        idToken["name"],
        idToken["family_name"]
      );
      store.UserMetaStore.setUserEmail(idToken["email"]);
      console.log(store.UserMetaStore.getUserGroups);
      //if a user has no groups redirect them to the error page
      if (store.UserMetaStore.getUserGroups === undefined) {
        console.log("no gorups");
        history.replace("/noGroups");
      }
      console.log(store.UserMetaStore);
    });
    return (
      <React.Fragment>
        <AppBar />
        <Container fluid style={{ marginTop: "0.1em" }}>
          <Switch>
            {_.map(routes, (route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </Container>
      </React.Fragment>
    );
  } else {
    return null;
  }
});

export default App;
