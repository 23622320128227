import { gql, useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import * as _ from "lodash";
import { Dropdown } from "semantic-ui-react";
import { CognitoUser, CognitoUserAttributes } from "../../gqlSchemaTypes/types";
const USER_QUERY = gql`
  query listCognitoUsers {
    listCognitoUsers {
      Username
      Attributes {
        Name
        Value
      }
    }
  }
`;
export interface ICognitoUserDropDownProps {
  setSelectedUser: (ids: string) => void;
  getSelectedUser: string;
}

type FormattedCognitoUsers = {
  email: string;
  uid: string;
  groups: any;
};

const CognitoUserDropDown: FunctionComponent<ICognitoUserDropDownProps> = observer(
  ({ setSelectedUser, getSelectedUser }) => {
    const { loading, error, data } = useQuery(USER_QUERY);
    let formattedGroups;
    //format the data returned for the dropdown component
    if (!loading && data) {
      let formattedUsers = _.map(
        data.listCognitoUsers,
        (user: CognitoUser, index) => {
          let currentUser: FormattedCognitoUsers = {
            uid: "",
            email: "",
            groups: [],
          };
          currentUser.uid = data.listCognitoUsers.Username;
          _.map(user.Attributes, (attribute: CognitoUserAttributes) => {
            if (attribute.Name === "email") {
              currentUser.email = attribute.Value;
            }
            switch (attribute.Name) {
              case "email":
                currentUser.email = attribute.Value;
                break;
            }
          });
          return currentUser;
        }
      );
      formattedGroups = _.map(
        formattedUsers,
        (item: FormattedCognitoUsers, index) => ({
          key: index,
          text: item.email,
          value: item.email,
        })
      );
    }
    return (
      <Dropdown
        placeholder="Users"
        fluid
        search
        selection
        onChange={(e, d) => {
          if (d.value) {
            console.log(d.value);
            setSelectedUser(d.value as string);
          }
        }}
        value={getSelectedUser}
        loading={loading}
        options={formattedGroups}
      />
    );
  }
);

export default CognitoUserDropDown;
