import * as _ from "lodash";
//given a list of required groups, checks if the user has the correct one.
const checkUserHasGroup = (
  requiredGroups: string[],
  userGroups: string[]
): boolean => {
  let intersection = _.intersection(requiredGroups, userGroups);
  return intersection.length > 0;
};

export { checkUserHasGroup };
