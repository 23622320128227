import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";
import cognitoRoutes from "./cognitoRoutes";
import defaultRoutes from "./defaultRoutes";

export type TRoute = {
  path: string;
  component: any;
  exact?: boolean;
  routes?: TRoute[];
};

//rendering of subroutes
//TODO: this needs to move somewhere else...
const RouteWithSubRoutes = (route: TRoute) => {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};

const routes: TRoute[] = [...cognitoRoutes, ...defaultRoutes];
export { routes, RouteWithSubRoutes };
